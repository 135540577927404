<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3">
            <ConfigurationNav />
          </div>
          <div class="col-12 col-md-9">
            <div class="card border-0 shadow-sm">
              <div class="card-header">
                <h4>
                  Tags
                </h4>
              </div>
              <div>
                <div v-if="Object.keys(tags).length !== 0" class="card-body">
                  <data-table
                    :headers="headers()"
                    :data="tags"
                    :method="'get'"
                    :sort-url="'/setup/tag'"
                  >
                    <template #name="{ item }">
                      {{ item.name }}
                    </template>
                    <template #slug="{ item }">
                      {{ item.slug }}
                    </template>
                    <template #created_at="{ item }">
                      {{ item.created_at | formatLocaleDateString }}
                    </template>
                    <template #updated_at="{ item }">
                      {{ item.updated_at | formatLocaleDateString }}
                    </template>
                    <template #actions="{ item }">
                      <div>
                        <!-- <router-link
                          :to="{name: 'configuration.view.edit', params:{id: item.id}}"
                          class="btn btn-sm btn-outline-secondary"
                        >
                          Edit
                        </router-link> -->
                        <DeleteButton
                          v-if="hasPermission('delete-tag')"
                          :uri="''"
                          :name="item.name"
                          :isSmall="true"
                          @confirm="deleteContent(item.id)"
                        />
                      </div>
                    </template>
                  </data-table>
                </div>
                <div v-else class="card-body">
                  <p class="text-muted">
                    This is a list of your tags.
                  </p>
                  <div class="p-4 rounded border-zip text-center">
                    <p class="mb-0">
                      No tags available!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import DataTable from "@/components/DataTable/DataTable.vue";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import DeleteButton from "@/components/Form/DeleteButton.vue";
import Permission from "@/utils/permission";

export default {
  components: {
    DataTable,
    Default,
    ConfigurationNav,
    DeleteButton,
  },
  extends: Permission,
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("/setup/tag").then((response) => {
      next((vm) => {
        vm.tags = response.data;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      tags: [],
      tagId: "",
    };
  },
  methods: {
    headers() {
      return [
        { title: "Name", key: "name", sortable: true, sortKey: "name" },
        { title: "Slug", key: "slug", sortable: false, sortKey: "default" },
        {
          title: "Created on",
          key: "created_at",
          sortable: true,
          sortKey: "created_at",
        },
        {
          title: "Last updated",
          key: "updated_at",
          sortable: true,
          sortKey: "updated_at",
        },
        { title: "Actions", key: "actions" },
      ];
    },
    deleteContent(id) {
      jsonAPI.delete("tag/" + id).then(() => {
        window.Bus.$emit("flash-message", {
          text: "Tag has been deleted",
          type: "success",
        });
        this.tags = this.tags.filter((obj) => {
          return obj.id !== id;
        });
        this.$store.dispatch("UPDATE_TAGS", this.tags);
        this.$store.dispatch("SET_TAGS", this.tags);
      });
    },
  },
};
</script>
