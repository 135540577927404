var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('div',{staticClass:"row fadeInUp",attrs:{"role":"main"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('ConfigurationNav')],1),_c('div',{staticClass:"col-12 col-md-9"},[_c('div',{staticClass:"card border-0 shadow-sm"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v(" Tags ")])]),_c('div',[(Object.keys(_vm.tags).length !== 0)?_c('div',{staticClass:"card-body"},[_c('data-table',{attrs:{"headers":_vm.headers(),"data":_vm.tags,"method":'get',"sort-url":'/setup/tag'},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"slug",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.slug)+" ")]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDateString")(item.created_at))+" ")]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDateString")(item.updated_at))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.hasPermission('delete-tag'))?_c('DeleteButton',{attrs:{"uri":'',"name":item.name,"isSmall":true},on:{"confirm":function($event){return _vm.deleteContent(item.id)}}}):_vm._e()],1)]}}],null,false,1708524030)})],1):_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted"},[_vm._v(" This is a list of your tags. ")]),_c('div',{staticClass:"p-4 rounded border-zip text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" No tags available! ")])])])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }